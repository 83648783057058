<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Add an invoice (i.e. rent)</h1>
        <app-look-up-customer
          v-if="quotation.customer === 0"
          @populateCustomer="populateCustomer" />
        <div class="mt-3">
          <router-link
            v-if="quotation.customer === 0"
            to="/customers/create">
            <v-btn small>Add New Customer</v-btn>
          </router-link>
        </div>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <h4>Customer</h4>
            <p v-if="quotation.customer.name">{{ quotation.customer.name }}</p>
            <h4>Customer address</h4>
            <span v-if="quotation.customer.address_1">{{ quotation.customer.address_1 }}</span>
            <span v-if="quotation.customer.address_2"><br />{{ quotation.customer.address_2 }}</span>
            <span v-if="quotation.customer.address_3"><br />{{ quotation.customer.address_3 }}</span>
            <span v-if="quotation.customer.address_4"><br />{{ quotation.customer.address_4 }}</span>
            <span v-if="quotation.customer.address_5"><br />{{ quotation.customer.address_5 }}</span>
            <span v-if="quotation.customer.postcode"><br />{{ quotation.customer.postcode }}</span>
            <h4 class="pt-4 pb-0">Customer telephone</h4>
            <span v-if="quotation.customer.tel">{{ quotation.customer.tel }}</span>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <v-btn
              small
              @click="quotation.customer = 0">
              Change Customer
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              v-model="quotation.customer_ref"
              label="Customer reference"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              :items="contacts"
              v-model="quotation.contact_id"
              item-text="name"
              item-value="id"
              label="Contact"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn
              @click="addNewContact = true"
              small>Add New Contact</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.first_name" label="First Name"></v-text-field>
          </v-col>
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.last_name" label="Last Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.tel" label="Tel"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn small @click="saveNewContact">Save contact</v-btn>
            &nbsp;
            <v-btn small @click="cancelNewContact">Cancel</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-textarea v-model="quotation.notes" label="Notes"></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="prettyDate"
                  label="Invoice date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                show-adjacent-months
                no-title
                @input="datePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.dom_vat_1"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Is the customer VAT registered?"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn @click="saveQuotation">
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LookUpCustomer from '@/components/Quotations/LookupCustomer.vue';
import axios from '../../axios';
import { format, parseISO } from 'date-fns';

export default {
  name: 'CreateQuotation',
  components: {
    appLookUpCustomer: LookUpCustomer,
  },
  data() {
    return {
      datePicker: false,
      customer: {},
      quotation: {
        customer: 0,
        date: null,
        drawing_ref: '',  
        site: '',
        details: '',
        sectorSeven: 0,
        department_id: 0,
        distance: '',
        drive_time: '',
        site_post_code: '',
      },
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      prettyDate: format(parseISO((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), 'EEEE, MMMM do yyyy'),
      contacts: [],
      message: '',
      showMessage: false,
      addressLocked: false,
      addNewContact: false,
      newContact: {
        first_name: '',
        last_name: '',
        email: '',
        tel: '',
      },
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    date() {
      this.prettyDate = format(parseISO(this.date), 'EEEE, MMMM do yyyy');
      this.quotation.date = this.date;
    },
  },
  methods: {
    cancelNewContact() {
      this.addNewContact = false;
      this.newContact = null;
    },
    saveNewContact() {
      const postData = {};
      postData.customerId = this.quotation.customer.id;
      postData.contact = this.newContact;
      axios.post(`/contacts/addFromQuote.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotation.contact_id = response.data.contact.id;
          const cont = {};
          cont.id = response.data.contact.id;
          cont.name = response.data.contact.full_name;
          this.contacts.push(cont)
          this.addNewContact = false;
          this.newContact = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    populateCustomer(customer) {
      axios.get(`/customers/get/${customer.id}.json?token=${this.token}`)
        .then((response) => {
          this.foundCustomers = [];
          this.customer = '';
          this.quotation.customer = response.data.customer;
          this.contacts = response.data.contacts;
          this.sites = response.data.sites;
          this.getJobRef();
        });
    },
    populatePostCode() {
      if (this.quotation.site.address) {
        const siteAddress = this.quotation.site.address;
        let site = '';
        for (let i = 0; this.sites.length > i; i += 1) {
          if (this.sites[i].address === siteAddress) {
            site = this.sites[i];
          }
        }
        this.quotation.site = site.address;
        this.quotation.site_post_code = site.postcode;
      }
    },
    saveQuotation() {
      const postData = {};
      postData.quotation = this.quotation;
      postData.date = this.date;
      axios.post(`/quotations/createInvoice.json?token=${this.token}&user=${this.userid}`, postData)
        .then((response) => {
          const quotationId = response.data.id;
          this.$router.push(`/invoices/add-lines/${quotationId}`);
        });
    },
    checkCustomer() {
      if (this.$route.params.customer) {
        const customerId = this.$route.params.customer;
        axios.get(`/customers/getSingleForQuote/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.quotation.customer = response.data.customer;
          this.contacts = response.data.contacts;
          this.sites = response.data.sites;
        });
      }
    },
    getJobRef() {
      axios.get(`/quotations/getJobRef.json?token=${this.token}`)
      .then((response) => {
        this.quotation.jobRef = response.data;
      });
    },
    resetDate() {
      this.prettyDate = null;
      this.date = null;
      this.quotation.date = null;
    },
  },
  mounted() {
    this.checkCustomer();
  }
}
</script>
